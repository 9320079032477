<!-- 审核 -->
<template>
  <div class="diseaseList">
    <!-- 封装tab组件 -->
    <KindTab :tabData="tabData" @clickTableTab="clickTableTab" />
    <!-- 封装selectForm筛选 -->
    <div class="tableList">
      <selectForm
        class="selectForm"
        :selectData="filterQueryFormItems"
        @search="search"
      ></selectForm>
      <!-- 封装表单table组件 -->
      <AntTable :columns="listHeader" :tableData="tableData" :loading="loading">
        <template slot="state" slot-scope="scope">
          <FillingStatus :value="scope.row" />
        </template>
        <template slot="fillRate" slot-scope="scope">
          <Percentage :value="scope.row"></Percentage>
        </template>
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <a href="javascript:;" @click="writeBtn(row)">
              <span>
                查看
              </span>
            </a>
            <a href="javascript:;" @click="writeBtn(row)">
              <span>
                上报
              </span>
            </a>
            <a href="javascript:;" @click="writeBtn(row)">
              <span>
                退回
              </span>
            </a>
          </div>
        </template>
      </AntTable>
    </div>
  </div>
</template>

<script>
import SelectForm from "@/components/selectForm/index.vue";
import AntTable from "@/components/antTable/index.vue";
import listHeader from "./listHeader";
import filterQueryFormItems from "./filterQueryFormItems";
import Percentage from "@/components/filters/Percentage.vue";
import FillingStatus from "@/components/filters/FillingStatus.vue";
import KindTab from "@/components/tab/kindTab/index.vue";
import { getMedicineProjectList } from "@/services/dict/index.js";
import { getMedicineProjectTab } from "@/services/diseaseList/index.js";
import { mapGetters } from "vuex";
export default {
  components: {
    SelectForm,
    AntTable,
    Percentage,
    FillingStatus,
    KindTab,
  },
  async created() {
    // this.search();
    // const { data } = await getMedicineProjectList();
    // this.$store.state.list = data.dataList;
    // console.log("data=========", data.dataList);
  },
  data() {
    return {
      loading: false,
      listQuery: {},
      tabData: [
        {
          id: 0,
          title: "呼吸",
          url: require("@/image/kind_icon/1.png"),
          activeUrl: require("@/image/kind_icon/11.png"),
        },
        {
          id: 1,
          title: "神经",
          url: require("@/image/kind_icon/2.png"),
          activeUrl: require("@/image/kind_icon/22.png"),
        },
        {
          id: 2,
          title: "生殖",
          url: require("@/image/kind_icon/3.png"),
          activeUrl: require("@/image/kind_icon/33.png"),
        },
        {
          id: 3,
          title: "心血管",
          url: require("@/image/kind_icon/4.png"),
          activeUrl: require("@/image/kind_icon/44.png"),
        },
        {
          id: 4,
          title: "运动",
          url: require("@/image/kind_icon/5.png"),
          activeUrl: require("@/image/kind_icon/55.png"),
        },
        {
          id: 5,
          title: "骨科",
          url: require("@/image/kind_icon/6.png"),
          activeUrl: require("@/image/kind_icon/66.png"),
        },
        {
          id: 6,
          title: "肿瘤",
          url: require("@/image/kind_icon/7.png"),
          activeUrl: require("@/image/kind_icon/77.png"),
        },
      ],
      /* 表格数据 */
      tableData: [
        {
          key: "1",
          name: "John Brown",
          status: 1,
          age: 32,
          address: "New York No. 1 Lake Park",
          time: "2023-06-09",
          value1: "",
          percentage: 100,
          value3: "急性心肌梗死",
          value4: "23456",
        },
      ],
    };
  },
  computed: {
    filterQueryFormItems,
    listHeader,
  },
  watch: {},
  methods: {
    async getListData(listQuery) {
      this.loading = true;
      const { data, code } = await getMedicineProjectTab(
        listQuery || this.listQuery
      );
      console.log("data===========1=====", data);
      if (code === 200) {
        this.tableData = data.dataList;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },
    /* 搜索按钮 */
    async search(listQuery) {
      console.log("listQuery", listQuery);
      this.listQuery = listQuery;
      this.getListData();
    },
    /* 切换tab */
    clickTableTab(e) {
      this.getListData({ ...this.listQuery, state: e.id });
    },
    writeBtn(e) {
      // console.log(e);
      const { code, projectCode } = e;
      this.$confirm(`确定填报病种类型为"${e.name}"的表单?`, "填报", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.push({
          name: "DiseaseWrite",
          query: { projectTabCode: code, projectCode },
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.diseaseList {
  width: 100%;
}
.selectForm {
  background: #fff;
  // max-width: 1405px;
  padding: 10px 0;
  margin: 10px auto;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  // max-width: 1405px;
  margin: 10px auto;
}

.operation {
  a {
    margin-right: 10px;
  }
}
</style>
